/**
 * Sticky header
 *
 * A simple utility class for sticky headers.
 *
 *============================================================================*/

.sticky-header {
  position: sticky;
  z-index: 2;
  top: 0;

  display: flex;
  align-items: center;

  background: white;
  box-shadow: 0 2px 4px 0 #00000033;
  padding: 0 190px 0 32px !important;

  @media(max-width: $screen-s) {
    h6 {
      display: none;
    }
  }
  @media(max-width: $screen-xs) 
     {
      position: fixed;
     }
     button {
      display: flex;
     }
  
}
