/**
 * Generic
 *
 * Style for generic & low-spec HTML elements.
 *
 *============================================================================*/



/* HTML
 *============================================================================*/

html {
  font-size: 14px;
  line-height: 1.4;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
}



/* Headings
 *============================================================================*/

h1, h2, h3, h4, h5, h6 {
  line-height: 1.25;
  font-weight: 400;
}

h1 { font-size: 40px !important; }
h2 { font-size: 36px !important; }
h3 { font-size: 32px !important; }
h4 { font-size: 28px !important; }
h5 { font-size: 24px !important; }
h6 { font-size: 20px !important; }



/* Horizontal rule
 *============================================================================*/

hr {
  border-top: 1px solid var(--tertiary);
  border-right: none;
  border-bottom: none;
  border-left: none;
}
