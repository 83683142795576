@use '@angular/material' as mat;
/**
 * Custom Theming for Angular Material
 *
 * For more information: https://material.angular.io/guide/theming
 *
 *============================================================================*/



/* Include the common styles for Angular Material
 *============================================================================*/
/**
 * We include this here so that you only have to load a single css file for
 * Angular Material in your app. Be sure that you only ever include this mixin
 * once!
 */

@include mat.core();



/* Theme defining
 *============================================================================*/
/**
 * Define the palettes for your theme using the Material Design palettes
 * available in palette.scss (imported above). For each palette, you can
 * optionally specify a default, lighter, and darker hue.
 * Available color palettes: https://material.io/design/color/
 */

$primary: (
  100: var(--primary-theme),
  500: var(--primary-theme),
  700: var(--primary-theme),
  contrast: (
    100: var(--primary-secondary),
    500: var(--primary-primary),
    700: var(--primary-primary)
  )
);
$accent: (
  100: var(--accent-theme),
  500: var(--accent-theme),
  700: var(--accent-theme),
  contrast: (
    100: var(--accent-secondary),
    500: var(--accent-primary),
    700: var(--accent-primary)
  )
);
$warn: (
  100: var(--warn-theme),
  500: var(--warn-theme),
  700: var(--warn-theme),
  contrast: (
    100: var(--warn-secondary),
    500: var(--warn-primary),
    700: var(--warn-primary)
  )
);

$jms-primary: mat.define-palette($primary);
$jms-accent : mat.define-palette($accent);
$jms-warn   : mat.define-palette($warn);

$jms-theme: mat.define-light-theme($jms-primary, $jms-accent, $jms-warn);

@include mat.all-component-themes($jms-theme);



/* Font
 *============================================================================*/
/**
 * Define a custom typography config that overrides the font-family as well as
 * the `headlines` and `body-1` levels.
 */

$custom-typography: mat.define-typography-config(
  $font-family: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif",
  $display-4:    mat.define-typography-level(20px, 25px, 400),
  $display-3:    mat.define-typography-level(20px, 25px, 400),
  $display-2:    mat.define-typography-level(20px, 25px, 400),
  $display-1:    mat.define-typography-level(20px, 25px, 400),
  $headline:     mat.define-typography-level(20px, 25px, 400),
  $title:        mat.define-typography-level(20px, 25px, 400),
  $subheading-2: mat.define-typography-level(14px, 21px, 400),
  $subheading-1: mat.define-typography-level(14px, 21px, 400),
  $body-2:       mat.define-typography-level(14px, 21px, 400),
  $body-1:       mat.define-typography-level(14px, 21px, 400),
  $caption:      mat.define-typography-level(12px, 18px, 400),
  $button:       mat.define-typography-level(14px, 14px, 500),
  $input:        mat.define-typography-level(12, 1.125, 400)
);

// Override the typography in the core CSS.
@include mat.core($custom-typography);
