@import "scss/00-setup/theme";
@import "scss/00-setup/angular-material-theme";

@import "scss/01-generic/resets";
@import "scss/01-generic/generic";

@import "scss/02-components/angular-material-components";
@import "scss/02-components/loading";
@import "scss/02-components/fb-fields";

@import "scss/03-utilities/sticky-header";
@import "scss/03-utilities/grid";
@import "scss/03-utilities/utilities";


.spotlight-dialog {
  > mat-dialog-container {
    border-radius: 8px;
    padding: 0;
  }
}

@media print {
  .no-print, .no-print *  {
    display: none !important;
  }
}

/**
 * Quick Edit
 */
jms-e-quick-edit {
  .action-inset {
    margin: 0 !important;
    padding: 0 !important;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
} 
.contextmenu-dialog {
  > mat-dialog-container {
    padding: 0;
  }

  &-item {
    text-align: left;
    width: 100%;
  }

  &-item.mat-button {
    text-align: left;
  }
}

.full-screen-dialog {
  mat-dialog-container.mat-dialog-container {
    border-radius: 0;

    background-color: transparent;

    padding: 0;
  }
}